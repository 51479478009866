<template>
  <div class="ad-protocol">
    <ad-navs :fixed="false"></ad-navs>

    <div class="content-container">
      <h2 class="title">经营资质</h2>
      <section class="main">
        <div class="zhizhao">
          <img src="../assets/images/01.png" alt="" width="100%">
        </div>
        <div class="beian">
          <img src="../assets/images/02.png" alt="" width="100%">
        </div>
      </section>
    </div>

    <ad-footer style="margin-top: 100px"></ad-footer>
  </div>
</template>

<script>
// 隐私政策
export default {
  name: "adProtocolComponent",
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .ad-protocol {
    padding-top: 50px !important;
    .content-container {
      max-width: 100% !important;
      padding: 20px !important;
      border-radius: 0px !important;
      .title {
        width: 100% !important;
        padding: 10px 0px 20px 0px !important;
        font-size: 18px !important;
      }
      .main {
        width: 100% !important;
        .zhizhao{
          width:100%;
          text-align: center;
        }
      }
    }
  }
}
.ad-protocol {
  width: 100%;
  padding-top: 130px;
  box-sizing: border-box;
  .content-container {
    max-width: 1180px;
    background: #ffffff;
    border-radius: 20px;
    margin: 0px auto;
    padding-bottom: 100px;
    .title {
      width: 948px;
      margin: 0px auto;
      font-size: 30px;
      font-family: "tj";
      font-weight: 600;
      color: #222222;
      line-height: 42px;
      padding: 100px 0px 59px 0px;
    }
    .main {
      width: 948px;
      margin: 0px auto;
      .zhizhao{
        width:100%;
        text-align: center;
      }
      .beian{
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>
